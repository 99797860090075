/* DISEÑO  */
:root {
    --azul: #145388;
    --naranja: #F19800;
    --azul-f: #0d375a;
    --naranja-f: #945f03;
}

/* BOOTSTRAP */
.btn {
    border-radius: 3.125em;
    font-size: .8em;
    padding: 0.5rem 1.25rem 0.5rem 1.25rem;
}

.btn-primary {
    background-color: var(--azul) !important;
    border-color: var(--azul) !important;
    color: white !important;
}

.btn-primary:active,
.btn-primary:hover {
    background-color: var(--azul-f);
    border-color: var(--azul-f);
    color: white !important;
}

.btn-danger {
    color: white !important;
}

.btn-secondary {
    background-color: var(--naranja);
    border-color: var(--naranja);
    color: white !important;
}

.btn-secondary:active,
.btn-secondary:hover {
    background-color: var(--naranja-f);
    border-color: var(--naranja-f);
    color: white !important;
}

/* GENERALES */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    background-color: #eeeeee;
    font-family: 'Nunito', sans-serif !important;
    color: #3A3A3A;
    position: relative;
}

img {
    max-width: 100%;
}

h1 {
    font-size: 1.75em;
    margin-bottom: 1em;
}

h2 {
    font-size: 1.5em;
}

li {
    list-style: none;
}

ul {
    padding: 0;
}

a {
    text-decoration: none;
    color: var(--azul) !important;
}

/* LOGIN */

.login {
    background: rgb(57, 118, 178);
    background: linear-gradient(90deg, rgba(57, 118, 178, 1) 0%, rgba(89, 125, 174, 1) 59%, rgba(136, 146, 178, 1) 100%);
    height: 40em;
    width: 100%;
    display: flex;
}

.login section {
    width: 50em;
    height: fit-content;
    background-color: white;
    margin: 3em auto;
    border-radius: 10px;
}

.start {
    background-color: #f5f5f5;
    border-radius: 0 10px 10px 0px;
    padding: 4em;
}

.start img {
    margin-bottom: 2em;
}

.login .row div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
}

.botones-login .btn-secondary {
    text-transform: uppercase;
}

.botones-login button {
    display: block;
    background-color: var(--azul);
    color: white;
    width: 100%;
    text-transform: uppercase;


}

.btn-default {
    color: #3a3a3a;
    font-size: .8em !important;
    padding-top: 1.4em;
    font-weight: 300;
}

.botones-login a {
    display: block;
}

@media screen and (max-width:991px) {
    .login {
        height: 100vh;
        align-items: center;
    }

    .login .row {
        flex-direction: column-reverse;
        width: 99%;
        margin-left: 1em;
        margin-right: 1em;


        border-radius: 1em;
    }

    .login .start {
        border-radius: 1em;
        padding: 2em;
    }
}

/* REGISTER */


.register {
    background: rgb(57, 118, 178);
    background: linear-gradient(90deg, rgba(57, 118, 178, 1) 0%, rgba(89, 125, 174, 1) 59%, rgba(136, 146, 178, 1) 100%);
    height: 40em;
    width: 100%;
    display: flex;
}

.register section {
    width: 50em;
    height: 33em;
    background-color: white;
    margin: auto;
    border-radius: 10px;
}

.register .info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1em;

}

.register .info>div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1em;

}

.register .info h2 {
    font-size: 1.1em;
    margin: 1em auto .5em auto;
    font-weight: bolder;
}

.register .info p {
    font-size: .8em;
}

.register .info img {
    width: 5em;
    height: 5em;
    margin-right: 1em;
}

.register .start {
    padding: 1em !important;
}

.register .start input,
.register .start select {
    font-size: .8em !important;

}



@media screen and (max-width:991px) {
    .register {
        height: 100vh;
        align-items: center;
    }

    .register section {
        height: fit-content;
    }

    .register .row {
        flex-direction: column-reverse;
        width: 99%;
        margin-left: 1em;
        margin-right: 1em;
        border-radius: 1em;
    }

    .register .start {
        border-radius: 1em;
        padding: 2em;
    }

    .register .info {
        display: none;
    }
}


/* HEADER Y SIDEBAR */

header {
    position: fixed;
    top: 0;
    left: 0;
    /* height: 60px; */
    z-index: 500 !important;
    width: 100% !important;
    max-width: 100% !important;
}

.pro-sidebar {
    position: fixed;
    top: 90px;
    left: 0;
    color: white;
    overflow-y: auto;
    max-height: calc(100vh - 90px);
}

.pro-sidebar a {
    color: white !important;
}

main {
    margin-top: 5em;
    padding: 2em 3em !important;
    margin-left: 90px;
    width: calc(100vw - 90px) !important;


}

@media (max-width: 991px) {
    main {
        padding: 2em .5em !important;
        width: calc(100vw - 80px) !important;
        margin-left: 80px !important;
    }

    header {
        max-width: 100vw;
    }
}




/* DASHBOARD */

.chartFOB {
    width: 100%;
    max-width: 100%;
}

.infoMonthChart {
    margin-top: 4em;
}

.infoMonthChart h3 {
    font-size: 1em;
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    order: 2;
    text-align: right;
    margin: .5em 0;
}

.infoMonthChart .col-md-4 section {
    margin-right: 1.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 99%;
}

.infoMonthChart .col-md-3 section:last-child {
    order: 1;
}

.infoMonthChart .row div section {
    border-radius: 1em;
    padding: 1.5em 1em .5em 0;
}


.infoMonthChart .row .ton section {
    background-color: #4c505c;
}

.infoMonthChart .row .usd section {
    background-color: #29C768;
}

.infoMonthChart .row .ope section {
    background-color: #00D8CB;
}

.infoMonthChart .recharts-wrapper {
    position: inherit !important;
    z-index: 300 !important;
    margin-left: -1em;
}


.averageChart h2 {
    padding: 1em 0;
    font-weight: 800;

}

.averageChart {
    background-color: white;
    border-radius: 1em;
    height: auto;
    max-height: 28em;
    overflow-y: scroll;
    width: 100%;
}

.averageChart .header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin: 0 0 1em 0 !important;
    max-width: 100%;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 400;
    padding: .5em 1em;
}

.averageChart .header h2 {
    margin-bottom: 0;
}

.averageChart .header select {
    width: 20em;
    font-size: 1em;
}

.averageChart>div {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    margin-bottom: 3em;
    max-width: 100%;

}

.averageChart>div>div {
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    max-width: 100%;

}

.averageChart img {
    width: 2em;
    height: 2em;
}

.averageChart h3 {
    font-size: .9em;
    font-weight: 700;
    color: #3A3A3A;
    text-transform: uppercase;
    order: 2;
    text-align: right;
    margin-left: 2em;
    margin-bottom: 0 !important;
}

.averageChart p {
    order: 3;
    margin-bottom: 0;
    margin-left: 2em;
    background-color: #28A745;
    padding: .5em;
    color: white;
    border-radius: 1em;

}

.averageChart>.text-center {
    display: block;
    margin: 3em auto;
}


.infoStats {
    margin: 4em 0;
    color: #145388;
}

.infoStats h3 {
    color: #6A6A6A;
    font-size: .8em;
    font-weight: 600;
}

.infoStats p {
    font-size: 2em;
    margin-bottom: 0;
}

.infoStats svg {
    height: 2em;
    width: 2em;
    margin-bottom: .5em;
}

.infoStats .row div div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 1em;
    padding: 1em 0;
}

.world {
    background-color: white;
    border-radius: 1em;
    padding: 1em;
    margin-bottom: 3em;
}

.world h2 {
    font-weight: 700;
    margin: .5em 0 1em 0;
}

.world .map-selected {
    fill: #1F82BF;
}

.world .map-unselected {
    fill: #f5f5f5;
}

.world .map-selected:hover,
.map-unselected:hover {
    cursor: pointer;
}

.world .row {
    justify-content: space-between;
}

.world .row .col-md-4 {
    display: flex;
    justify-content: flex-end;
    padding-right: .5em;
}

.world table {
    text-align: center;
}

.world table thead tr th {
    color: #212529;
    font-weight: 700;
    font-size: .9em;
    text-transform: uppercase;
    padding-bottom: 1em;
    border-bottom: 2px solid #dee2e6;
}


.world table tbody td {
    border-bottom: 1px solid rgba(136, 146, 178, 0.151);
    margin: 1em 0;
    padding: .5em 0;
    font-size: .7em;
}

.world table tbody tr td:first-child,
.world table thead tr th:first-child {
    text-align: left !important;
}


.world .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 65%;

}

.world .header p {
    margin-bottom: 0;
}

.world .row .countries {
    padding-right: 0 !important;
    max-height: 10em;
}

.world .countries thead tr th,
.world .countries tbody tr td {
    padding-right: 2em;
}

.world .countries tbody tr {
    height: 3em !important
}

.chartFOB .ads .ad {
    border-radius: 1em;
    margin-bottom: 1em !important;
}

.chartFOB .ads .ad img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 1em;
}

.verification-account {
    margin-bottom: 1em;
}

.verification-account .MuiAlert-message {
    font-family: 'Nunito', sans-serif !important;
}

.verification-account .MuiAlert-icon {
    font-size: 2em;
}

.verification-account .MuiPaper-root-MuiAlert-root {
    border-radius: 1em !important;
}

.verification-account .btn {
    margin-top: 1em;
    display: block;
    width: fit-content;
    border: 1px solid white;
    border-radius: 2em;
    color: white;
}

.verification-account .btn:hover {
    background-color: white;
    color: #d32f2f;
}




@media (max-width: 991px) {
    .chartFOB .ads {
        display: none;
    }

    .infoMonthChart .row div {
        margin: 1em 0;
    }

    .infoStats .row div {
        margin: .5em 0;
    }

    .world .row .col-md-4 {
        justify-content: center;
    }

    .world .header {
        flex-direction: column;
        width: 100%;
    }

    .world .map-img {
        margin-top: -5em !important;
        margin-bottom: -3em !important;
    }

    .averageChart .header {
        flex-direction: column;
    }

    .averageChart .header select {
        max-width: 100%;
    }

    .averageChart p {
        margin-left: 0;
        display: block;
        margin-top: 1em;
    }

    .averageChart h3 {
        margin: 2em 0 2em 0;
    }

    .averageChart img {
        display: none;
    }



}

/* PAGE MARKET  */

.market div {
    background-color: white;
    border-radius: 10px;
    padding: 1em;
    max-width: 48% !important;
}

.market div p {
    font-size: .9em;
}

.market div h2 {
    margin-bottom: 1em;
}

.market {
    display: flex;
    justify-content: space-around;
}

.market-publicar {
    text-align: center;
    background-color: #145388;
    color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    align-content: center;
    max-height: 15em;
    margin: 4em 0;
}

.market-publicar .btn {
    border: 1px solid white !important;
}

.market-publicar .btn:hover {
    background-color: white !important;
    color: #145388 !important;
}

.market-publicar img {
    background-color: #28a745 !important;
    border-radius: 50%;
}

@media screen and (max-width: 991px) {
    .market div {
        max-width: 90% !important;
        margin: 1em 0;
    }

    .market-publicar {
        max-height: fit-content;
        padding: 2em 0;
    }

    .market-publicar .img {
        margin: 1em auto;
        max-width: 80%;
    }
}

/* MIS PRODUCTOS */

.productsByUser .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
}

.product {
    background-color: white;
    border-radius: 1em;
    margin-bottom: 2em;
    width: 99%;
    position: relative;
}

.product h2 {
    color: #3A3A3A;
    font-weight: bolder;
    text-align: center;
    padding: 1em 0 .5em 0;
}


.product ul li {
    font-size: .8em;
    padding: 1em 0;
    border-top: 1px solid #dee2e6;
    padding: 1em;
    color: #3A3A3A;

}

.product ul li span {
    font-weight: 700;
}

.product .precio {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #145388;
    color: white;
    padding: .5em 1em;
    border-radius: 10px;
    font-weight: bolder;
}

.product img {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 8em;
    border-radius: 1em 1em 0 0;
}

@media screen and (max-width: 991px) {
    .product {
        width: 100%;
    }

    .productsByUser .header {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 2em;
    }

    .productsByUser .header .btn {
        align-self: center;

    }
}


/* PRODUCTOS BY ID */

.productId {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.productId .product {
    background-color: white;
    border-radius: 1em;
    width: 100%;
    position: relative;
}

.productId .product h2 {
    color: #3A3A3A;
    font-weight: bolder;
    text-align: center;
    padding: 1em 0 .5em 0;
}


.productId .product ul li {
    font-size: .8em;
    padding: 1em 0;
    border-top: 1px solid #dee2e6;
    padding: 1em;
    color: #3A3A3A;
    text-align: center;

}

.productId .product ul li span {
    font-weight: 700;
}

.productId .images {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 70%;
    width: 70%;
    margin-left: 2em;
    position: relative;
}

.productId .images img {
    width: 100%;
    max-width: 100%;
    height: 24em;
    object-fit: cover;
    object-position: center;
    border-radius: 1em;
}

.productId .images .images_list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 99%;
    margin-left: 1em;
}

.productId .images .images_list img {
    width: 70%;
    height: 8em;
    object-fit: cover;
    object-position: center;
    border-radius: 1em;
    margin-bottom: 1em;
}

.productId .buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.productId .precio {
    position: absolute;
    top: -.3em;
    left: -.3em;
    background-color: #145388;
    color: white;
    padding: .5em 1em;
    border-radius: 10px;
    font-weight: bolder;
    font-size: 1.5em;
}

.productId .share {
    position: absolute;
    bottom: -0.5em;
    left: 14.5em;
    background-color: white;
    color: white;
    padding: .5em 1em;
    border-radius: 10px;
    font-weight: bolder;
    font-size: 1.5em;
}

@media screen and (max-width:991px) {
    .productId {
        flex-direction: column;
    }

    .productId .images {
        width: 100%;
        max-width: 100%;
        margin-top: 3em;
        margin-left: 0;
        flex-direction: column;
    }

    .productId .images .images_list {
        width: 100%;
        margin-left: 0;
        margin-top: 1em;
    }

    .productId .images .images_list img {
        width: 100%;
    }

    .productId .share {
        position: absolute;
        bottom: -0.5em;
        left: 0em;
        background-color: white;
        color: white;
        padding: .5em 1em;
        border-radius: 10px;
        font-weight: bolder;
        font-size: 1.5em;
    }
}

.not-exist {
    text-align: center;
    margin: 5em 0 10em 0;
}

.not-exist p {
    font-size: 2em;
    font-weight: bolder;
    text-align: center;
}


/* NEW PRODUCT */
.formNewProduct {
    background-color: white;
    border-radius: 1em;
    padding: 1em;
}

.formNewProduct h2 {
    text-align: center;
    padding: 1em 0;
}

.formNewProduct .form-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1em;
    width: 100%;
}

.formNewProduct .form-group.tipe div {
    width: 30%;

}

.formNewProduct .clasificacion {
    margin: 2em 0;
}

.formNewProduct .clasificacion label:first-child {
    display: block;
    font-size: 1.5em;
    margin-bottom: .5em;
}

.formNewProduct .clasificacion .form-check {
    padding: 0 4em;
}

.formNewProduct .mm {
    font-size: .7em !important;
    padding-bottom: 2em;
}

.formNewProduct .caracteristicas {
    padding: 0 !important;

}

.formNewProduct .caracteristicas h2 {
    text-align: left;
}

.formNewProduct .caracteristicas label,
.formNewProduct .caracteristicas input,
.formNewProduct .caracteristicas select {
    font-size: .8em;
    margin-bottom: .5em;
}

.formNewProduct .invalid {
    width: 100% !important;
    margin-top: 0.25rem !important;
    font-size: .7em !important;
    color: #DC3545 !important;
}

.formNewProduct .caracteristicas .img img {
    width: 8em;
    height: 8em;
    object-fit: cover;
    object-position: center;
    border-radius: 1em;
    margin: 2em auto;
    text-align: center;
    display: block;
}

.formNewProduct .caracteristicas .galery {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-start;
    max-width: 100%;
    margin: 2em auto;
    flex-wrap: wrap;
}

.formNewProduct .caracteristicas .galery img {
    width: 4em;
    height: 4em;
    object-fit: cover;
    object-position: center;
    border-radius: 1em;
    margin: 0 .5em;
    text-align: center;
    display: block;
}

.formNewProduct .caracteristicas video {
    height: 8em;
    width: 8em;
    object-fit: cover;
    object-position: center;
    border-radius: 1em;
    margin: 2em auto;
    text-align: center;
    display: block;
}

.swal2-confirm .swal2-styled {
    background-color: var(--azul) !important;
    border-color: var(--azul) !important;
}

@media screen and (max-width: 991px) {
    .formNewProduct .clasificacion .form-check {
        padding: 0 3em;
        flex-direction: column;
    }

}


/* TRANSACTIONS */

.table-transactions .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    border-radius: 1em;
    padding: 1em;
}

.table-transactions .header div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 30%;

}


.table-transactions .table {
    background-color: white;
    border-radius: 1em;
    margin: 3em 0 1em 0;
}

.table-transactions .table thead tr th {
    text-align: center;
    padding-top: 1em;
}


.table-transactions .table tbody {
    font-size: .8em;
    text-align: center;
    vertical-align: middle;
}

.table-transactions .table tbody .red span {
    background-color: #DC3545;
    color: white;
    border-radius: 1em;
    padding: .5em;

}

.table-transactions .table tbody .green span {
    background-color: #28A745;
    color: white;
    border-radius: 1em;
    padding: .5em;
}

.table-transactions .table tbody .greenP span {
    color: #28A745;
    border-radius: 1em;
    padding: .5em;
}

.table-transactions .table tbody .redP span {
    color: #DC3545;
    border-radius: 1em;
    padding: .5em;
}



.table-transactions .table tbody .blue span {
    background-color: var(--azul);
    color: white;
    border-radius: 1em;
    padding: .5em;
    font-size: .9em;
}

.table-transactions .table tbody .yellow span {
    background-color: var(--naranja);
    color: white;
    border-radius: 1em;
    padding: .5em;
    font-size: .9em;

}


.pagination {
    display: flex;
    justify-content: center;
}

.pagination button {
    margin: 0 1em;
}

.table-transactions p.not {
    text-align: center;
    margin: 5em 0 10em 0;
    font-size: 1.5em;
}

@media (max-width: 991px) {
    .table-transactions .table {
        max-width: 100% !important;
        display: block;
        overflow-x: auto;

    }

    .table-transactions .header {
        flex-direction: column;
    }

    .table-transactions .header div {
        width: 100%;
        margin: .5em 0;
    }


}

/* ALL PRODUCTS */
.allProducts .header {
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    border-radius: 1em;
    padding: 1em;
    margin-bottom: 2em;
}

.header-bids {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3em;
}

@media (max-width: 991px) {
    .allProducts .header div {
        margin: 1em 0 .5em 0;
    }



    .allProducts .header {
        margin: 0 !important;
    }

    .allProducts .row {
        width: 100% !important;
        margin: 1em 0;
    }

    .header-bids {
        flex-direction: column;
        align-items: flex-start;
    }

    .header-bids h1 {
        margin-bottom: 1em;
    }

    .header-bids .btn {
        align-self: center;
    }

}

/* TRANSACTION BY ID */

.transactionId .row {
    justify-content: space-between;
    align-items: flex-start;
    padding: 1em;
}

.transactionId .row section {
    background-color: white;
    border-radius: 1em;
    padding: 1em 1em 0 1em;
    width: 99%;
    position: relative;
}

.transactionId .row section h2 {
    text-align: left;
    padding: .5em 0;
    text-transform: uppercase;
    font-size: 1.3em;
    font-weight: bolder;
}

.transactionId .row .vendedor ul li,
.transactionId .row .comprador ul li {
    font-size: .7em;
    padding: 1em 0;
    border-top: 1px solid #dee2e6;
    padding: 1em;
    color: #3A3A3A;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.transactionId .row .producto ul li,
.transactionId .row .transaccion ul li {
    font-size: .7em;
    padding: 1em 0;
    border-top: 1px solid #dee2e6;
    padding: 1em;
    color: #3A3A3A;
    text-align: left;
}

.transactionId .row section img {
    width: 5em;
    height: 5em;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    position: absolute;
    top: -1em;
    right: -.5em;
}

.btns-transactions {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.btns-transactions .btn {
    background-color: white;
    border-radius: 1em;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1em 2em;
    width: 100%;
    padding: 0.5rem 0 !important;
}

.btns-transactions .btn svg {
    font-size: 5em;
    color: #145388;
}

.btns-transactions .btn span {
    font-size: .8em;
    color: #145388;
    text-transform: uppercase;
    font-weight: bolder;
}

.transaction.btn-primary {
    display: block !important;
    width: fit-content !important;
    margin: 2em auto !important;

}

.finalizar-operacion {
    background-color: white;
    border-radius: 1em;
    box-shadow: 0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04);
    padding: 1em;
    width: fit-content;
    margin: 1em auto;
}

.content-transactions .btn {
    display: block !important;
    width: fit-content !important;
    margin: 2em auto !important;
}

.MuiTypography-root,
.MuiTimeline-root .MuiTypography-root-MuiTimelineOppositeContent-root,
.MuiTimeline-root .MuiTypography-root {
    font-family: 'Nunito', sans-serif !important;
}



.MuiTimeline-root .MuiTypography-root svg {
    width: .8em;
    color: #3A3A3A;
}

.MuiTimeline-root .MuiTypography-root {
    color: #a09e9e !important;
    font-size: .9em !important;
}

.MuiTimelineDot-root {
    background-color: #145388 !important;
}

.MuiTypography-root-MuiTimelineContent-root {
    padding-top: 0 !important;
}

.content-transactions form {
    background-color: white;
    border-radius: 1em;
    padding: 2em 3em;
    width: 90%;
    margin: 3em auto;
}

.content-transactions form h3 {
    text-align: center;
    margin-bottom: 1em;
}

.content-transactions .table-files {
    background-color: white;
    border-radius: 1em;
    padding: 2em 3em;
    width: 90%;
    margin: 3em auto;
    text-align: center;
}

.content-transactions .table-files tbody {
    font-size: .8em;
    text-align: center;
    vertical-align: middle;
}

.content-transactions .table-files h3 {
    text-align: center;
    margin-bottom: 1em;
}

.content-transactions .table-files .btn {
    color: white !important;
    margin: .1em auto !important;
}

.content-transactions .table-files .btn:hover {
    color: white !important;
    text-decoration: none !important;
}

@media screen and (max-width:991px) {
    .btns-transactions {
        flex-direction: column;

    }

    .btns-transactions .btn {
        width: 50%;
    }

}


/* DEMAND BY ID */


.demandId .row {
    justify-content: space-between;
}

.demandId .product {
    background-color: white;
    border-radius: 1em;
    position: relative;
}

.demandId .product h2 {
    color: #3A3A3A;
    font-weight: bolder;
    text-align: center;
    padding: 1em 0 .5em 0;
}


.demandId .product ul li {
    font-size: .8em;
    padding: 1em 0;
    border-top: 1px solid #dee2e6;
    padding: 1em;
    color: #3A3A3A;
    text-align: center;

}

.demandId .product ul li span {
    font-weight: 700;
}

.demandId .images {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
}

.demandId .images img {
    width: 100%;
    max-width: 100%;
    height: 22em;
    object-fit: cover;
    object-position: center;
    border-radius: 1em;
}


.demandId .buttons {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.demandId .precio {
    position: absolute;
    top: -.3em;
    left: -.3em;
    background-color: #145388;
    color: white;
    padding: .5em 1em;
    border-radius: 10px;
    font-weight: bolder;
    font-size: 1.5em;
}

@media screen and (max-width:991px) {
    .demandId .images {
        margin-top: 3em;

    }

}

/* USER PROFILE */

.MuiButtonBase-root-MuiTab-root {
    font-family: 'Nunito', sans-serif !important;
}

.userProfile .row {
    margin-top: 3em;
}

.userProfile .card {
    background-color: white;
    border-radius: 1em;
    padding: 1em;
    position: relative;
}

.userProfile .card h2 {
    margin: 3.5em 0 1em 0;
    text-align: center;
    font-weight: bolder;
}


.userProfile .card .img img {
    width: 8em;
    height: 8em;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    position: absolute;
    top: -4em;
    left: calc(50% - 4em);
}

.userProfile .card svg {
    width: 1em;
    height: 1em;
}

[data-testid="GppBadIcon"] {
    color: #DC3545;
}

[data-testid="VerifiedUserIcon"] {
    color: #28A745;
}

.marketByUser .card {
    background-color: white;
    border-radius: 1em;
    padding: 1em;
    position: relative;
    color: #3a3a3a;
    margin: 1em 0;
}

.marketByUser .card .img-user img {
    width: 3em;
    height: 3em;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    position: absolute;
    top: .5em;
    left: .5em;
}

.marketByUser .card h2 {
    margin: 0em 0 .5em 3em;
    text-align: left;
    font-size: 1em;
}

.marketByUser .card .date {
    position: absolute;
    top: 3em;
    left: 5em;
    font-size: .8em;
    color: #909090;
}

.marketByUser .card h3 {
    margin: 1em 0 .4em 0em;
    text-align: left;
    font-weight: bolder;
}

.marketByUser .card .img-product img {
    width: 10em;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 1em;
    position: absolute;
    top: 0;
    right: 0;
}

.marketByUser .card p {
    margin-bottom: 0;
}

.css-19kzrtu {
    padding: 1.5em 0 !important;
}

@media screen and (max-width: 991px) {
    .userProfile .col-md-8 {
        margin-top: 2em;
    }

    .css-1gsv261 {
        max-width: 100% !important;
    }

    .marketByUser .card .img-product img {
        position: relative;
        width: 100%;
        height: 10em;
        object-fit: cover;
        margin-top: 1em;
    }
}


/* EDIT USER PROFILE */

.edit-profile form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.edit-profile .row {
    width: 75%;
}

.edit-profile form section {
    margin-top: 1em;
    background-color: white;
    border-radius: 1em;
    padding: 1em;
}


.edit-profile form .avatar {
    width: 25%;
    text-align: center;
}

.edit-profile form .avatar img {
    width: 10em;
    height: 10em;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
}

@media screen and (max-width:991px) {
    .edit-profile form .avatar {
        width: 100%;
    }

    .edit-profile form .avatar label {
        display: block;
    }


    .edit-profile .row {
        width: 100%;
        flex-direction: column;
    }

    .edit-profile .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}


/* PASSWORD EDIT */

.editPassword {
    width: 70%;
}

.editPassword form {
    background-color: white;
    border-radius: 1em;
    padding: 1em;
}







/* FOOTER */
footer {
    padding: 2em 2em 1em 4em;
    border-top: 1px solid #d7d7d7;
}

footer .row {
    justify-content: space-between;
}

footer p {
    color: #909090 !important;
    font-size: .9em;
}

footer ul {
    display: flex;
}

footer ul li {
    list-style: none;
    margin-bottom: 1em;
    text-transform: uppercase;
    font-size: .8em;
    margin-left: 1em;
    border-right: 1px solid #145388;
    padding-right: .5em;

}

footer ul li a,
footer ul li a:hover,
footer ul li a:active {
    color: #145388;
}

@media (max-width: 991px) {
    footer {
        padding: 1em .5em 1em 6em !important;
        max-width: 100% !important;
    }


    footer ul {
        flex-direction: column;
        justify-content: center;
        margin-top: 3em !important;
    }

    footer ul li {
        border: none;
        text-align: center;
        font-size: 1.2em;
    }

    footer p.text-center {
        max-width: 100%;
        font-size: .9em;

    }
}

/* ALL NEWS */

.allNews {
    justify-content: space-between;
    align-items: center;
}

.allNews .card {
    border-radius: 1em;
    margin-bottom: 2em;
}

.allNews .card .icons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1em;
}

.allNews .card .icons span {
    margin-right: 1em;
}


.allNews .card .css-11soth5-JoyButton-root {
    background-color: var(--azul) !important;
}

.allNews .col-md-12 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
    background-color: white;
    border-radius: 1em;
    padding: 1.5em 1em;
}

.allNews .col-md-12 .form-group {
    width: 50%;
}

/* NEW BY ID */

.newId .post {
    background-color: white;
    border-radius: 1em;
}

.newId .header {
    font-weight: bolder;
    position: relative;
}

.newId .header h2 {
    font-size: 2em;
    margin: 1em auto .5em auto;
    text-align: center;
}

.newId .header .autor {
    text-align: center;
}


.newId .header .date {
    position: absolute;
    right: 0;
    top: -4em;
    font-size: 1.2em !important;
    background-color: white;
    padding: .5em 1em;
    border-radius: 1em;
}


.newId .img img {
    width: 100%;
    height: 15em;
    object-fit: cover;
    object-position: center;
    border-radius: 1em;
    box-shadow: inset 10px 7px 158px 200px rgba(0, 0, 0, 0.75);
}

.newId .content {
    margin-bottom: 2em;
    padding: 0 1em 2em 1em;
    text-align: left;
}


.newId .content>p {
    margin: 3em 0;
    text-align: center;
}

.newId .content div {
    margin: 1em 0;
}

.newId .interactions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    border-radius: 1em;
    padding: 1em 1em;
    font-size: 1.5em;
    color: var(--azul);
}

.newId .interactions p {
    margin-bottom: 0;
}

.newId>.comments {
    background-color: white;
    border-radius: 1em;
    padding: 1em 1em;
    margin: 2em 0;
}

.newId>.comments h3 {
    text-align: center;
    margin-top: .5em;
}

.newId>.comments .comment {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18);
    border-radius: 1em;
    margin: 2em auto;
    padding: 1em;
    position: relative;
}

.newId>.comments .comment .comment-content {
    margin-left: 7em;
    margin-top: .5em;
}


.newId>.comments .comment .comment-header {
    margin-left: 7em;
    margin-top: 1em;
}

.newId>.comments .comment .comment-header .name {
    font-weight: bolder;
    font-size: 1.2em;
    margin-bottom: 0;
}

.newId>.comments .comment .comment-header .date {
    font-size: .8em;
    color: #909090;
    margin-bottom: 0;
}

.newId>.comments .comment img {
    width: 5em;
    height: 5em;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    position: absolute;
    left: 1em;
    top: calc(50% - 2.5em);
}

@media (max-width: 991px) {
    .allNews .col-md-12 {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;

    }

    .allNews .col-md-12 div,
    .allNews .col-md-12 .form-group {
        width: 100%;
        margin: .5em auto;
    }

    .allNews .col-md-12 div a {
        width: 100%;
        display: block;
    }

    .newId .interactions {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .newId .interactions div {
        margin-bottom: 1em;
    }

    .newId>.comments .comment img {
        left: 0;
        width: 5em;
        height: 5em;
    }

    .newId>.comments .comment .comment-header,
    .newId>.comments .comment .comment-content {
        margin-left: 5em;
    }
}


/* FORM NEW */
.formNew div {
    background-color: white;
    border-radius: 1em;
    padding: 1em .4em;
}

.formNew {
    justify-content: space-between;
}

.formNew .col-md-3 {
    width: 24% !important;
}

.formNew .btn-primary {
    display: block;
    width: fit-content;
    margin: 1em auto;
}

.formNew .quill {
    padding: 0 !important;
}

.formNew .ql-toolbar.ql-snow+.ql-container.ql-snow {
    height: 15em !important;
}

.formNew img {
    width: 100%;
    height: 15em;
    object-fit: cover;
    object-position: center;
    border-radius: 1em;
    box-shadow: inset 10px 7px 158px 200px rgba(0, 0, 0, 0.75);
    margin-bottom: 2em;
}

@media screen and (max-width:991px) {

    .formNew .col-md-3 {
        width: 100% !important;
        margin-top: 1em;
    }
}

/* LIST SERVICES */

.listServices .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
}

.listServices .header h1 {
    margin-bottom: 0;
}


.listServices .row {
    margin: 1em 0;
    justify-content: flex-start;
    align-items: center;
}

.listServices .card h2 {
    text-align: center;
    font-size: 1.1em;
    margin-bottom: 1em;
    color: #3A3A3A;
}

.listServices .card {
    margin: 1em 0;
    position: relative;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18);
}

.listServices .card img {
    width: 5em;
    height: 5em;
    object-fit: cover;
    object-position: center;
    border-radius: 1em;
    margin: 1em auto;
}

.listServices .card p.count {
    position: absolute;
    right: 0;
    top: -1em;
    font-size: 1em;
    font-weight: bolder;
    padding: .5em 1em;
    border-radius: 1em;
    color: white;
    background-color: var(--azul);
}

.imgService {
    width: 4em;
    height: 4em;
    object-fit: cover;
    object-position: center;
    border-radius: 1em;
    box-shadow: inset 10px 7px 158px 200px rgba(0, 0, 0, 0.75);
}

/* CHATS */

.modalChats .icon {
    border-radius: 50%;
    margin-right: 1em;
    background-color: #3A3A3A;
    padding: 1em;
    max-width: fit-content;
    position: relative;
}

.modalChats p.count {
    position: absolute;
    right: 0;
    top: -1em;
    font-size: .8em;
    font-weight: bolder;
    padding: .5em 1em;
    border-radius: 50%;
    color: white;
    background-color: #2e7d32;
}

.modalChats .icon svg {
    width: 1.5em;
    height: 1.5em;
    fill: white;
}

.modalChats {
    position: fixed;
    bottom: 1em;
    right: 1em;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    z-index: 450 !important;
}

.modalChats p.h2 {
    border-bottom: 2px solid #dee2e6;
    padding-bottom: .2em;
    margin-bottom: 1em;

}

.modalChats .content {
    padding: 1em;
    background-color: white;
    border-radius: 1em;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18);
    margin-bottom: 1em;
    max-height: 50vh;
    overflow-y: scroll;
    min-width: 25em;
}

.modalChats .chat {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
}

.modalChats .chat img {
    width: 3em;
    height: 3em;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    margin-right: 1em;
}

.modalChats .chat p {
    margin-bottom: 0;
}

/* CHAT BY ID */

.chats .row {
    display: flex;
    justify-content: flex-start;
}

.sidebar__conversations {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1em;
    background-color: white;
    border-radius: 1em;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18);
    margin-bottom: 1em;
    height: 100%;
}

.sidebar__conversations h2 {
    margin-bottom: 1em;
}

.sidebar__conversation {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
    justify-content: center
}

.sidebar__conversation img {
    width: 3em;
    height: 3em;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    margin-right: 1em;
}

.sidebar__conversation p {
    margin-bottom: 0;
}

.chatById {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 1em;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18);
    height: calc(100vh - 10em);
    position: relative;
}

.chatById .chat-header {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1em;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18);
    padding: .5em;
    border-radius: 1em 1em 0 0;

}

.chatById .chat-header h2 {
    margin-bottom: 0;
    font-size: 1.3em;

}

.chatById .chat-header img {
    width: 3em;
    height: 3em;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    margin-right: 1em;
}

.chatById .messages {
    max-height: 100% !important;
    overflow-y: scroll;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: column;
    margin-bottom: 4em;
}

.chatById .messages .chat-date {
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: 0 auto 1em auto;
    margin-bottom: 1em;
    background-color: #f5f5f5;
    border-radius: 1em;
    padding: .5em 1em;
    font-size: .8em;
    font-weight: bold;
}

.chatById .chat .chat_message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1em;
    border-radius: 1em;
    padding: .5em 1em;
    max-width: 50%;
    background-color: #F4F4F4;
}

.chatById .chat .chat_message.from {
    align-items: flex-start;
    background-color: var(--azul);
    color: white;
    margin-right: 1em;
    margin-left: auto;
}

.chatById .chat .chat_message.to {
    align-items: flex-start;
    background-color: #F4F4F4;
    color: #3A3A3A;
    margin-left: 1em;
    margin-right: auto;
}

.chatById .chat .date {
    font-size: .7em;
    margin-bottom: .1em;
    margin-top: -.5em;
    align-self: flex-end;
    text-align: right;
}

.chatById .chat .date svg {
    width: .8em;
    height: .8em;
    margin-bottom: .1em;
}

.chatById .send-message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    background-color: #F4F4F4;
    border-radius: 0 0 1em 1em;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

}

.chatById .send-message input {
    width: 100%;
    border: none;
    outline: none;
    padding: .5em 1em;
    border-radius: 1em;
    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18);
}

.chatById .send-message .icons {
    cursor: pointer;
    margin: 0 .5em;

}

.welcome__chat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-color: white;
    border-radius: 1em;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18);
    padding: 1em;
}

.welcome__chat h2 {
    margin: 1em auto .5em auto;
    font-weight: bolder;
}

.welcome__chat p {
    font-size: .9em;
}

.welcome__chat img {
    width: 15em;
    height: 15em;
}

/* breakpoint md */
@media screen and (max-width: 991px) {
    .modalChats .content {
        width: calc(100vw - 110px);
        min-width: calc(100vw - 110px);
        max-width: calc(100vw - 110px);
    }

    .chats>.row>.col-12 {
        margin-bottom: 1em;
    }

    .sidebar__conversations {
        max-height: 13em;
        height: 13em;
        overflow-y: scroll;
    }

    .chatById {
        height: calc(100vh - 27em);
    }

    .chatById .chat .chat_message {
        max-width: 90%;
    }

    .welcome__chat {
        text-align: center;
    }
}

/* ADMIN */

/* listUser */

.listUser>.row>.header {
    background-color: white;
    border-radius: 1em;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18);
    padding: 1em;
    margin-bottom: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.listUser .header .search {
    display: flex;
    align-items: center;
    width: 50%;
}

.listUser .header label {
    margin-right: 1em;
}

.listUser .card {
    border-radius: 1em;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18);
    margin-bottom: 2em;
    background-color: white;
    border-radius: 1em;
}

.listUser .card .header {
    position: relative;
    padding: 2em 1em;
    border-radius: 1em 1em 0 0;
}

.listUser .card .header h2 {
    margin-bottom: 0;
    font-size: 1.1em;
    font-weight: bolder;
    text-align: left;
}

.listUser .card .header img {
    position: absolute;
    top: .2em;
    right: -.7em;
    width: 4em;
    height: 4em;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    margin-right: 1em;
}

.listUser .card .info {
    padding: 0 1em;
}

.listUser .card .info p {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    font-size: .85em;
}

.listUser .card .info p strong {
    font-weight: bold;
    font-size: .9em;
}

.listUser .card .buttons {
    display: flex;
    justify-content: center;
    margin: 1em 0;
}

.listUser .card .buttons .btn {
    margin: 0 .5em;
}


/* adminRegister */
.adminRegister form {
    padding: 1em 2em;
    background-color: white;
    border-radius: 1em;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18);
}

.adminRegister form>.d-flex {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
}

.adminRegister form>.d-flex>div {
    width: 49%;
}


@media screen and (max-width: 991px) {
    .listUser {
        width: 100%;
    }

    .listUser .header {
        flex-direction: column;
    }

    .listUser .header .search {
        width: 100%;
        margin-top: 1em;
    }

    .listUser .header .btn {
        width: 100% !important;
    }

    .listUser .card .info p {
        flex-direction: column;
        text-align: center;
    }

    .adminRegister form>.d-flex {
        display: flex;
        flex-direction: column;
    }

    .adminRegister form>.d-flex>div {
        width: 100%;
    }
}

/* ADMIN - Campaigns */
.campaigns .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
}

.campaigns .header h1 {
    margin-bottom: 0;
}

.campaigns>.table {
    background-color: white;
    border-radius: 1em;
    padding: 1em;
}


.cardsAds>.row>.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
    background-color: white;
    border-radius: 1em;
    padding: 2em 1em;
}

.cardsAds>.row>.header .input-group {
    width: 95% !important;
}

.cardsAds .card {
    background-color: white;
    border-radius: 1em;
    padding: 1em;
    margin-bottom: 2em;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18);
}

.cardsAds .card h2 {
    font-size: 1.1em;
    font-weight: bolder;
    text-transform: uppercase;
}

.cardsAds .card p {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1em;
}

.cardsAds .card p svg {
    margin-right: .5em;
}

.cardsAds .card img {
    width: 20em;
    height: 20em;
    object-fit: cover;
    object-position: center;
    border-radius: 1em;
    margin-bottom: 1em;
}

.cardsAds .card .icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.cardsAds .card .icons p {
    margin-bottom: 0;
}

.cardsAds .card .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 1em;
}

.cardsAds .card .buttons .btn-success {
    color: white !important;
}

/* ADMIN - AD BY ID  */

.adById h1 {
    position: relative;
}

.adById .state {
    position: absolute;
    top: 2.5em;
    right: 8em;
}

.adById .state .approved {
    background-color: #198754;
    color: white;
    padding: .5em 1em;
    border-radius: 1em;
}

.adById .state .not_approved {
    background-color: #dc3545;
    color: white;
    padding: .5em 1em;
    border-radius: 1em;
}

.adById img {
    width: 20em;
    height: 20em;
    object-fit: cover;
    object-position: center;
    border-radius: 1em;
}

.newAd form {
    padding: 1em 2em;
    background-color: white;
    border-radius: 1em;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18);
}

.newAd form div {
    margin-bottom: 1em;
}

.newAd form img {
    width: 15em;
    height: 15em;
    object-fit: cover;
    object-position: center;
    border-radius: 1em;
    margin-bottom: 1em;
}

.newAd form .img {
    text-align: center;
}

.newAd form .banner {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.newAd form .banner>* {
    width: 50%;
}

.newAd form .btn-dark {
    color: white !important;
}

/* ADMIN - TABLE  DOCUMENTS */

.tableDocuments {
    background-color: white;
    border-radius: 1em;
    padding: 2em !important;
    margin-bottom: 1em;
    text-align: center;
}

.tableDocuments tbody tr {
    vertical-align: middle;

}

.listDocuments>.row>.header {
    background-color: white;
    border-radius: 1em;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18);
    padding: 1em;
    margin-bottom: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.listDocuments>.row>.header .state,
.listDocuments>.row>.header .search {
    width: 49%;
}

/* PAGE LIST ENTEPRISES */

.pageListEnterprises .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
}

.pageListEnterprises .header h1 {
    margin-bottom: 0;
}


/* FORM NEW ENTERPRISE */

.formNewEnterprise form {
    padding: 1em 2em;
    background-color: white;
    border-radius: 1em;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.18);
}

.formNewEnterprise form .avatar {
    display: flex;
    flex-direction: row;
}

.formNewEnterprise form .avatar .img {
    width: 40%;
    text-align: center;
}

.formNewEnterprise form .avatar .img img {
    width: 10em;
    height: 10em;
    object-fit: cover;
    object-position: center;
    border-radius: 1em;
    margin-bottom: 1em;
}

.formNewEnterprise form .avatar div:first-child {
    width: 60%;
}

.formNewEnterprise form button {
    text-align: center !important;
    margin: 0 auto !important;
    display: block !important;
}


@media screen and (max-width: 991px) {
    .campaigns .header {
        flex-direction: column;

    }

    .campaigns .header button {
        text-align: center;
        margin-top: 1em;
    }

    .campaigns .table,
    .tableDocuments {
        max-width: 100% !important;
        display: block;
        overflow-x: auto;
    }

   .campaigns .table,
   .campaigns .table thead,
   .campaigns .table tbody,
   .campaigns .table th,
   .campaigns .table td,
   .campaigns .table tr {
        display: block;
    }

   .campaigns .table thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

   .campaigns .table tr {
        border: 1px solid #ccc;
    }

   .campaigns .table td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        text-align: center;

    }

   .campaigns .table td:before {
        position: absolute;
        left: 6px;
        width: 45%;
        white-space: nowrap;
    }

   .campaigns .table td:before {
        content: attr(data-label);
    }

    .campaigns .header .btn {
        margin-top: 1em;
    }

    .cardsAds>.row>.header>.me-md-3 {
        margin-bottom: 1em;
    }

    .cardsAds .card img {
        width: 15em;
        height: 15em;
        object-fit: cover;
        object-position: center;
        border-radius: 1em;
    }

    .cardsAds .card .icons.mb-3 {
        flex-direction: column;
    }

    .cardsAds .card .icons.mb-3 p {
        margin-bottom: 1em;
    }

    .cardsAds .card .buttons {
        display: flex;
        flex-direction: column;
    }

    .cardsAds .card .buttons > * {
        margin-bottom: 1em;
    }

    .adById .btn {
        margin: 1em auto;
        text-align: center;
        display: block;
    }

    .adById .state {
        top: 10em;
        right: 0;
    }

    .listDocuments>.row>.header {
        flex-direction: column;
    }

    .listDocuments>.row>.header .state, .listDocuments>.row>.header .search {
        width: 100%;
        margin-bottom: 1em; 
    }

    .pageListEnterprises .header {
        flex-direction: column;
    }

    .pageListEnterprises .header .btn {
        margin-top:1em;
    }

    .pageListEnterprises .listUser .card .header {
        padding: 1.5em 1em;
        display: flex;
        align-items: flex-start;
        margin-bottom: 1em;

    }

    .pageListEnterprises .listUser .card .header h2 {
        text-align: left !important;
    }

}

/* CALIFICATION */

.calification .group {
    background-color: white;
    border-radius: 1em;
    padding: 1em;
    width: auto;
    margin: 2em;

}

.calificationByUser .calification-card {
    background-color: white;
    border-radius: 1em;
    padding: 1em;
    width: auto;
    margin: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}